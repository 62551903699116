import { atom } from 'recoil';

export const activeLinkState = atom({
    key: "activeLink",
    default: "/"
})

export const expandSidebarState = atom({
    key: "activeSidebar",
    default: false,
})