import DetailPage from '@/components/Pages/DetailPage/DetailPage';
import React from 'react';

const ValidatorDetail = () => {

  return (
    <>
      <DetailPage />
    </>
  );
};

export default ValidatorDetail;