import { fetchLatestSignatures } from "@/apis/store_api.ts";
import { CircularProgress, Tooltip } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./SignedBlock.module.scss";
import { Link } from "react-router-dom";

const SignedBlock = (props) => {
  const { data: validationDetail } = props;

  const [currentBlockData, setCurrentBlockData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [newBlockIds, setNewBlockIds] = useState([]);  // New state to track new block IDs

  const fetchData = (validationDetail) => {
    if (validationDetail?.address) {
      fetchLatestSignatures(validationDetail?.address)
        .then((res) => {
          const resNewBlockIds = res.map((item) => item.block_number);

          // Identify new blocks by comparing block numbers
          const newBlocks = resNewBlockIds.filter(
            (blockNumber) => !oldData.some((oldItem) => oldItem.block_number === blockNumber)
          );

          console.log(res);

          if (newBlocks.length > 0) {
            // Combine new blocks with existing newBlockIds and remove duplicates
            setNewBlockIds((prevIds) => [...new Set([...prevIds, ...newBlocks])]);

            // Set a timeout to reset the new block indicator after a certain time (e.g., 4000ms)
            setTimeout(() => {
              setNewBlockIds((prevIds) => prevIds.filter((id) => !newBlocks.includes(id)));
            }, 4000);
          }

          setCurrentBlockData(oldData); // Save the current data for comparison
          setOldData(res);
          setIsloading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setIsloading(false);
        });
    } else {
      setCurrentBlockData([]);  // Reset currentBlockData when there is no address
      setNewBlockIds([]); // Clear newBlockIds when there is no address
    }
  };
  useEffect(() => {
    let timer;

    if (validationDetail && validationDetail?.address) {
      setIsloading(true);
      fetchData(validationDetail);
      // Don't call fetchData immediately, let the setInterval handle it
      timer = setInterval(() => fetchData(validationDetail), 8000);
    }

    // Cleanup function to clear the interval when the component is unmounted
    return () => {
      clearInterval(timer);
    };
  }, [validationDetail]);

  useEffect(() => {
    // Clear new block IDs after a certain time (e.g., 4000ms)
    const timeoutId = setTimeout(() => {
      setNewBlockIds([]);
    }, 4000); // Adjust the timeout duration to match the fetchData function

    return () => clearTimeout(timeoutId);
  }, [newBlockIds]);



  return (
    <div className={styles["root"]}>
      <div className={styles["container"]}>
        <div className={styles["title"]}>Signed Block
        </div>
        <div className={styles["line"]}></div>
        <div className={styles["block-container"]}>
          <div className={classNames(styles["block-list"], isLoading ? styles["block-list--loading"] : "")}>
            {
              isLoading ? <CircularProgress /> : oldData.map((item, index) => (
                <Tooltip key={`block-${index}`} title={<div key={`block-${index}`} className={styles["tooltip"]}>Block Number: {item.block_number}</div>}>
                  <Link
                    className={classNames(
                      styles["block"],
                      item.sign_status ? styles["block--signed"] : "",
                      newBlockIds.includes(item.block_number) ? styles["block--new"] : ""
                    )}
                    to={`/blocks/${item.block_number}`}
                  ></Link>
                </Tooltip>
              ))
            }
          </div>
          <div className={styles["info"]}>
            <div className={styles["info-item"]}><div className={classNames(styles["info-item-block"], styles["info-item-block--signed"])}></div> Signed Block</div>
            <div className={styles["info-item"]}><div className={styles["info-item-block"]}></div> Missed Block</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignedBlock;
