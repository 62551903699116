import BlockDetailCom from '@/components/Pages/Blocks/BlockDetails/BlockDetails';
import React from 'react';

const BlockDetail = () => {

  return (
    <>
      <BlockDetailCom />
    </>
  );
};

export default BlockDetail;