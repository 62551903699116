import TransactionComponent from '@/components/Pages/Transaction/Transactions';
import React from 'react';

const Transactions = () => {

  return (
    <>
      <TransactionComponent />
    </>
  );
};

export default Transactions;