import { useEffect, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchBlocks, fetchLastBlock } from '@/apis/store_api.ts';
import { activeLinkState } from '@/recoil/listState';
import { useSetRecoilState } from 'recoil';
import styles from "./Blocks.module.scss";
import { formatDistanceToNow, format } from 'date-fns';
import { Tooltip } from '@mui/material';

const Blocks = () => {
  const setActiveLink = useSetRecoilState(activeLinkState);
  const [data, setInitData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [isUserClickLoading, setUserIsloading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const fetchDataInterval = useRef(null);

  const fetchData = async () => {
    try {
      // setIsloading(true);
      if (paginationModel.page > 0) {
        setUserIsloading(true);
      }
      const dataRes = await fetchBlocks(paginationModel.page + 1, paginationModel.pageSize);
      const lastBlockData = await fetchLastBlock();

      if (dataRes && dataRes.data) {
        const finalData = dataRes.data.map((block, index) => ({
          ...block,
          index: index + 1,
          height: block.header.height,
          hash: block.block_id,
          txs: block.tx_hashes.length,
          time: block.header.time,
          proposer: block.header.proposer_address,
        }));
        setInitData(finalData);
        setRowCountState(lastBlockData?.header?.height || 0);
      } else {
        console.error("Invalid data structure:", dataRes);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsloading(false);
      setUserIsloading(false);
    }
  };

  useEffect(() => {
    setActiveLink("/blocks");
    fetchData();
    // //Set up interval for fetching new data every 4 seconds
    fetchDataInterval.current = setInterval(() => {
      if (paginationModel.page === 0) {
        fetchData();
      }
    }, 5000);

    //Clear the interval when the component unmounts
    return () => clearInterval(fetchDataInterval.current);
  }, [setActiveLink, paginationModel.page, paginationModel.pageSize]);

  const columns = [
    { field: 'index', headerName: 'No', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false, renderCell: (data) => <span> {<span>{data.row.index + (paginationModel.page) * paginationModel.pageSize}</span>} </span> },
    { field: 'height', headerName: 'Height', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => <a href={`/blocks/${data.value}`}>{data.value}</a> },
    { field: 'hash', headerName: 'Hash', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => <Tooltip title={data.value} arrow>  <span> {data.value.substring(0, 6)}...{data.value.substring(data.value.length - 6)} </span></Tooltip> },
    { field: 'txs', headerName: 'Txs', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false },
    { field: 'proposer', headerName: 'Proposer', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 400, sortable: false, renderCell: (data) => <a href={`/validators/${data.value}`}>{data.value}</a> },
    { field: 'time', headerName: 'Time', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => <Tooltip title={format(new Date(data.value), 'yyyy/MM/dd hh:mm:ss')} arrow> <span>{formatDistanceToNow(new Date(data.value), { addSuffix: true })}</span></Tooltip> },
  ];

  return (
    <div className={styles["root"]}>
      <div className={styles["container"]}>
        <div className={styles["title-container"]}>
          <h1 className={styles["title"]}>Blocks</h1>
        </div>
        {data.length === 0 && !isLoading && <p>No results found</p>}
        <div className={styles["table"]}>
          <DataGrid
            getRowId={(row) => row.index}
            rows={data}
            columns={columns}
            pageSizeOptions={[10]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableColumnFilter
            paginationMode="server"
            pageSize={paginationModel.pageSize}
            rowCount={rowCountState}
            loading={isUserClickLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Blocks;
