import ValidatorList from '@/components/Pages/ListPage/ValidatorList';
import React from 'react';

const Validators = () => {

  return (
    <>
      <ValidatorList />
    </>
  );
};

export default Validators;