import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import NavigationBar from '../NavigationBar/NavigationBar';
import styles from "./Layout.module.scss";
import Sidebar from '../Sidebar/Sidebar';
import Background from '@/assets/neon-background.jpg';
import { useRecoilValue } from 'recoil';
import { expandSidebarState } from '@/recoil/listState';
import classNames from 'classnames';

const Layout = () => {
  const expandSidebar = useRecoilValue(expandSidebarState);

  return (
    <div className={styles["root"]}>
      {/* <NavigationBar /> */}
      <div className={styles["background"]}>
        <img src={Background} alt="background" />
      </div>
      <div>
        <Header />
        <div className={styles["content-container"]}>
          <Sidebar />
          <div className={classNames(styles["content"], !expandSidebar && styles["content--collapse"])}>
            {/* <Sidebar /> */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;