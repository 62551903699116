import { CircularProgress } from "@mui/material";
import { fetchBlockDetail } from '@/apis/store_api.ts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from "./BlockDetails.module.scss";
import BlockInformations from './BlockInformations/BlockInformations';
import HashesTable from './Hashes/Hashes';

const BlockDetails = () => {
  const { id } = useParams();

  const [blockDetails, setBlockDetails] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);

      const blockDetails = await fetchBlockDetail(id);
      setBlockDetails(blockDetails || {});
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors if needed
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div className={styles["root"]}>
      <div className={styles["container"]}>
        {loading ? (
          <CircularProgress /> // Display CircularProgress while data is being fetched
        ) : (
          <>
            <BlockInformations data={blockDetails} />
            <HashesTable data={blockDetails} />
          </>
        )}
      </div>
    </div>
  );
};

export default BlockDetails;
