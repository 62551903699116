import React, { useState } from 'react';
import styles from "./Sidebar.module.scss";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeLinkState, expandSidebarState } from '@/recoil/listState';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Logo from '@/assets/namada-icon.svg';
import GridViewIcon from '@mui/icons-material/GridView';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PaidIcon from '@mui/icons-material/Paid';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import XIcon from '@mui/icons-material/X';
import RedditIcon from '@mui/icons-material/Reddit';

const Sidebar = () => {


  const activeItem = useRecoilValue(activeLinkState);
  const expandSidebar = useRecoilValue(expandSidebarState);
  const setExpandSidebar = useSetRecoilState(expandSidebarState);

  const navigationList = [
    {
      text: <span><GridViewIcon /> {expandSidebar && "Block"}</span>,
      link: "/blocks",
      isExternal: false
    },
    {
      text: <span><ChecklistIcon /> {expandSidebar && "Validator"}</span>,
      link: "/validators",
      isExternal: false
    },
    {
      text: <span><PaidIcon /> {expandSidebar && "Transaction"}</span>,
      link: "/transactions",
      isExternal: false
    },
    {
      text: "Blog",
      link: "https://namada.net/blog",
      isExternal: true
    },
    {
      text: "Talks",
      link: "https://namada.net/talks.html",
      isExternal: true
    },
    {
      text: "Specs",
      link: "https://specs.namada.net/",
      isExternal: true
    },
    {
      text: "Docs",
      link: "https://docs.namada.net/",
      isExternal: true
    },
    {
      text: "X",
      link: "https://twitter.com/namada",
      isExternal: true,
      icon: <XIcon />
    },
    {
      text: "Reddit",
      link: "https://www.reddit.com/r/Namada/",
      isExternal: true,
      icon: <RedditIcon />
    },
  ];

  return (
    <div className={classNames(styles["root"], !expandSidebar && styles["root--collapse"])}>
      <div className={styles["container"]}>
        {/* <div className={styles["logo"]}><img src={Logo} alt="logo" /></div> */}
        <div className={styles["title"]}>
          {expandSidebar ? (
            <>
              Navigation <CloseIcon className={styles["close"]} onClick={() => setExpandSidebar(false)} />
            </>
          ) : (
            <>
              <span></span> <MenuIcon className={styles["close"]} onClick={() => setExpandSidebar(true)} />
            </>
          )}
        </div>

        <div className={styles["sidebar-list"]}>
          {navigationList.map((item, index) => (
            item.isExternal ? (
              <a key={`item-${index}`} className={classNames(styles["sidebar-item"], activeItem === item.link ? styles["sidebar-item--active"] : "")} href={item.link} target="_blank" rel="noopener noreferrer">
                {item.icon || item.text}
              </a>
            ) : (
              <Link key={`item-${index}`} className={classNames(styles["sidebar-item"], activeItem === item.link ? styles["sidebar-item--active"] : "")} to={item.link}>
                {item.text}
              </Link>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;