import BlocksCom from '@/components/Pages/Blocks/Blocks';
import React from 'react';

const Blocks = () => {

  return (
    <>
      <BlocksCom />
    </>
  );
};

export default Blocks;