import TransactionDetailCom from '@/components/Pages/Transaction/TransactionDetails/TransactionDetails';
import React from 'react';

const TransactionDetail = () => {

  return (
    <>
      <TransactionDetailCom />
    </>
  );
};

export default TransactionDetail;